/* eslint-disable */
import React, { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import NetworkProvider from './contexts/networkContext';
import TaskProvider from './contexts/taskContext';

import AjaxInterceptors from './utils/AjaxInterceptors';
import AppRoute from './routes/index';
import Loader from './components/Loader/Loader';
import { Skeleton } from '@material-ui/lab';
import ScrollToTop from './ScrollToTop';
//import { SnackbarProvider } from 'notistack';
import { messaging } from './init-fcm';

function App() {
  /*React.useEffect(()=> {

  messaging.requestPermission()
    .then(async function() {
      const token = await messaging.getToken();
      console.log(token);
    })
    .catch(function(err) {
      console.log("Unable to get permission to notify.", err);
    });
navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

}, [])*/
  return (
    // <SnackbarProvider maxSnack={3}>
    <HashRouter>
      <ScrollToTop />
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <NetworkProvider>
          <TaskProvider>
            <>
              <AjaxInterceptors />
              <AppRoute />
            </>
          </TaskProvider>
        </NetworkProvider>
      </Suspense>
    </HashRouter>
    // {/* </SnackbarProvider> */}
  );
}

export default App;
