/* eslint-disable  */
import { NETWORK_EVENTS } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  errors: false,
  success: false,
  count: 0,
  showLoader: true,
  data: null,
};

export default (state, { type, payload }) => {
  switch (type) {
    case NETWORK_EVENTS.NETWORK_CALL_REQUEST:
      return { ...state, loading: true, count: state.count + 1, ...payload };

    case NETWORK_EVENTS.NETWORK_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: payload.message,
        data: payload,
        count: state.count - 1,
      };

    case NETWORK_EVENTS.NETWORK_CALL_FAIL:
      return { ...state, loading: false, success: false, error: payload, count: state.count - 1 };
    case NETWORK_EVENTS.NETWORK_CALL_FAIL_401:
      console.log("NETWORK_CALL_FAIL_401");
      return { ...state, loading: false, success: false, error: payload, count: state.count - 1 };

    case NETWORK_EVENTS.NETWORK_CALL_RESET:
      return initialState;

    default:
      return state;
  }
};
