/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */
import React, { lazy, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import appNav from './appNav';
import { Skeleton } from '@material-ui/lab';
import Loader from '../components/Loader/Loader';
import InstallTripswitchHardware from '../screens/Main/InstallTripswitchHardware/InstallTripswitchHardware';

const Main = lazy(() => import('../screens/Main'));

const PrivateRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);

  useEffect(() => {
    if (!cookies?.token) {
      navigate('../login');
      setAuth(false);
    } else {
      setAuth(true);
    }
  }, [cookies.token, navigate]);

  return auth ? <Component {...props} /> : <Loader />;
};

const appRoutes = [
  {
    path: 'app',
    element: <PrivateRoute as={Main} />,
    children: appNav,
  },
  {
    path: 'install-tripswitch-hardware',
    element: <InstallTripswitchHardware />,
  },
];

export default appRoutes;
