/* eslint-disable  */


import React, { memo } from 'react';
import { useRoutes } from 'react-router-dom';
import NotFound from '../screens/NotFound';
import appRoutes from './appRoutes';
import authRoutes from './authRoutes';

const Route = () => {
  const element = useRoutes([...authRoutes,...appRoutes,
    { path: '*', element: <NotFound /> },
  ]);

  return element;
};

Route.propTypes = {};

export default memo(Route);
