/* eslint-disable  */
import React, { useEffect, useCallback, useContext } from 'react';
import {
  Snackbar,
  ExpansionPanel,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemText,
  Box,
  Button,
} from '@material-ui/core';

import { Alert, Skeleton } from '@material-ui/lab';
import Cookies from 'js-cookie';
import ajax from './ajaxHelper';
import { NetworkContext } from '../contexts/networkContext';
import { NETWORK_EVENTS, TASK_EVENT } from '../constants/actionTypes';
import Loader from '../components/Loader/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import useStyles from '../screens/Main/Main.style';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import Cancel from '@material-ui/icons/Cancel';
import { Grid, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { TaskContext } from '../contexts/taskContext';
import { green, red } from '@material-ui/core/colors';

import { withStyles } from '@material-ui/core/styles';

import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

//import useStyles from '../screens/Main/Main.style';
import MuiDialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CryptoJS from 'crypto-js';
//import Cookies from 'js-cookie';

const DialogClose = withStyles()((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <Grid disableTypography {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          color="primary"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Grid>
  );
});

/**
 * AjaxIterceptor created for global loader and global error handler
 *
 * @returns
 */
const AjaxInterceptors = () => {
  const [state, dispatch] = useContext(NetworkContext);
  const [taskState, taskDispatch] = useContext(TaskContext);
  const navigate = useNavigate();
  const locationRef = useLocation();
  const classes = useStyles();
  let key = 'iFrasCwdJh';
  const CREATE_TASK_APP_PATH = '/app/program-detail/create-new-task';
  const onRequest = useCallback(
    (request) => {
      const user = Cookies.get('user');
      let companyDetails = {};
      if (user) {
        const parsedUser = JSON.parse(atob(user));
        // let bytes = CryptoJS.AES.decrypt(user, key);
        // const parsedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (parsedUser.companyId) {
          companyDetails = {
            companyId: parsedUser.companyId,
          };
        }
      }

      const hosttype = Cookies.get('hosttype');
      let hostTypeDetails = {};
      if (hosttype) {
        const parsedHostType = atob(hosttype);
        hostTypeDetails = {
          hosttype: parsedHostType,
        };
      }

      const token = Cookies.get('token');
      let authorization = {};
      if (token) {
        authorization = {
          Authorization: `Bearer ${token}`,
        };
      }

      const updatedRequest = {
        ...request,
        headers: {
          ...request.headers,
          ...authorization,
          ...hostTypeDetails,
          ...companyDetails,
        },
      };
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_REQUEST,
        payload: request.networkParams || {},
      });
      return updatedRequest;
    },
    [dispatch],
  );

  const onRequestError = useCallback(
    (error) => {
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
        payload: error,
      });
      return Promise.reject(error);
    },
    [dispatch],
  );

  const onResponse = useCallback(
    (response) => {
      if (response.data.fieldMessage) {
        response.data.message = '';
      }

      if (!response.data.status) {
        dispatch({
          type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
          payload: new Error(response.data.message ? response.data.message : null),
        });

        return Promise.reject(new Error(response.data.message ? response.data.message : null));
      }

      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    },
    [dispatch],
  );

  const onResponseError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status == 401) {
          Cookies.remove('token');
          Cookies.remove('hosttype');
          Cookies.remove('user');
          Cookies.remove('path');
          setTimeout(() => {
            navigate('../../login'), 200;
          });
          dispatch({
            type: NETWORK_EVENTS.NETWORK_CALL_FAIL_401,
            payload: { message: 'Your session has timed out. Please login again.', status: false },
          });
        }
      }
      if (error.response && error.response.status == 422) {
        if (error.request && error.request.response) {
          let requestErrorResponse = JSON.parse(error.request.response);
          if (requestErrorResponse && requestErrorResponse.message) {
            error.message = requestErrorResponse.message;
          }
        }
        dispatch({
          type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
          payload: error,
        });
      }

      return Promise.reject(error);
    },
    [dispatch],
  );

  const handleClose = useCallback(() => {
    dispatch({ type: NETWORK_EVENTS.NETWORK_CALL_RESET });
  }, [dispatch]);

  useEffect(() => {
    const requestInterceptor = ajax.interceptors.request.use(onRequest, onRequestError);
    const responseInterceptor = ajax.interceptors.response.use(onResponse, onResponseError);

    return () => {
      ajax.interceptors.request.eject(requestInterceptor);
      ajax.interceptors.response.eject(responseInterceptor);
    };
  }, [onRequest, onRequestError, onResponse, onResponseError]);

  let sample = {};

  if (state.data && state.data.data) {
    sample.onClickAway = () => {};
  }

  const getStatusText = (taskState) => {
    if (taskState && taskState.pendingBucket.length > 0) {
      return TASK_EVENT.SAVE_IN_PROGRESS;
    }
    if (taskState && taskState.completionBucket.length > 0) {
      if (taskState.tasksStatusBucket.length == taskState.completionBucket.length) {
        return TASK_EVENT.SAVE_FAILED;
      } else {
        return TASK_EVENT.FEW_FAILED;
      }
    } else {
      return TASK_EVENT.TASK_SAVED_SUCCESSFULLY;
    }
  };

  const getAccordionDispStatus = (taskState) => {
    if (taskState && taskState.completionBucket.length > 0) {
      if (taskState.tasksStatusBucket.length == taskState.completionBucket.length) {
        return false;
      }
      return true;
    }
    return false;
  };

  const [accordState, setAccordState] = React.useState(true);

  const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({}))(MuiDialogActions);
  return (
    <Choose>
      <When condition={state.loading && state.showLoader}>
        <Loader />
      </When>
      <When condition={!!state.error && state.count === 0 && state.error.message !== 'null'}>
        <Snackbar open={!!state.error} autoHideDuration={6000} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
            {state.error.message}
          </Alert>
        </Snackbar>
      </When>
      <When condition={!!state.success && state.count === 0}>
        <Snackbar
          open={!!state.success}
          autoHideDuration={6000} //{(state.data && state.data.data) || 6000}
          onClose={handleClose}
          ClickAwayListenerProps={sample}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose} //{state.data && state.data.data ? false : handleClose}
            severity="success"
          >
            {state.success}
          </Alert>
        </Snackbar>
      </When>
      <When condition={taskState.tasksStatusBucket && taskState.tasksStatusBucket.length > 0}>
        <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
          <Snackbar
            style={{
              left: 'auto',
              right: 'auto',
              width: '25%',
              height: 'auto',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              bottom: 24,
              transform: 'none',
            }}
            open={
              taskState.tasksStatusBucket &&
              taskState.tasksStatusBucket.length > 0 &&
              taskState.panelOpen
            }
            // autoHideDuration={(getStatusText(taskState) == TASK_EVENT.TASK_SAVED_SUCCESSFULLY) ? 150000 : undefined }
            // onClose={() => {
            //   taskDispatch({
            //     type: TASK_EVENT.TASK_COMPLETE_RESET,
            //     payload: null,
            //   });
            // }}
            ClickAwayListenerProps={{ onClickAway: () => {} }}
          >
            <ExpansionPanel
              expanded={accordState || getAccordionDispStatus(taskState)}

              // onClick={() => {
              //   alert("second called  ")
              //   if (getAccordionDispStatus(taskState)) {
              //     taskDispatch({
              //       type: TASK_EVENT.TASK_COMPLETE_RESET,
              //       payload: null,
              //     });
              //   } else {
              //     setAccordState(!accordState);
              //   }
              // }
              //}
            >
              <ExpansionPanelSummary
                expandIcon={
                  // getAccordionDispStatus(taskState) ? (
                  //   <ArrowDropUpOutlinedIcon />
                  // ) : (
                  //   <ArrowDropDownOutlinedIcon />
                  // )

                  (getStatusText(taskState) == TASK_EVENT.TASK_SAVED_SUCCESSFULLY ||
                    getStatusText(taskState) == TASK_EVENT.FEW_FAILED ||
                    getStatusText(taskState) == TASK_EVENT.SAVE_FAILED) &&
                  locationRef &&
                  locationRef.pathname != CREATE_TASK_APP_PATH ? (
                    <Cancel />
                  ) : accordState ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  if (
                    (getStatusText(taskState) == TASK_EVENT.TASK_SAVED_SUCCESSFULLY ||
                      getStatusText(taskState) == TASK_EVENT.FEW_FAILED ||
                      getStatusText(taskState) == TASK_EVENT.SAVE_FAILED) &&
                    locationRef &&
                    locationRef.pathname != CREATE_TASK_APP_PATH
                  ) {
                    //dismiss
                    taskDispatch({
                      type: TASK_EVENT.TASK_COMPLETE_RESET,
                      payload: null,
                    });
                  } else {
                    setAccordState(!accordState);
                  }
                  //  setAccordState(!accordState);
                }}
              >
                <Typography className={classes.heading}>{getStatusText(taskState)}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <List
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                  }}
                >
                  {taskState.tasksStatusBucket &&
                    taskState.tasksStatusBucket.map((eachTask, i) => {
                      console.log(eachTask, 'dfsdfsdf');
                      return (
                        <ListItem>
                          <Typography variant=" h5" noWrap>
                            {eachTask.taskName}
                          </Typography>
                          <ListItemSecondaryAction>
                            <CircularProgress
                              size={30}
                              variant="static"
                              style={
                                eachTask.progressVal == 100
                                  ? eachTask.status == 'SAVED'
                                    ? { color: green[500] }
                                    : { color: red[500] }
                                  : {}
                              }
                              value={eachTask.progressVal}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Snackbar>
        </Grid>
      </When>

      <When condition={false}>
        <Grid item>
          <Dialog onClose={() => {}} aria-labelledby="customized-dialog-title" open={true}>
            <DialogClose className={classes.closeButton} onClose={() => {}}></DialogClose>

            <DialogContent style={{ textAlign: 'center' }}>
              <Typography variant="h1">Are you sure you want to</Typography>
              <Typography variant="h1">logout?</Typography>
              <Box mt={4} />
              <Typography variant="h3" style={{ fontWeight: '400' }}>
                Your task saving is in progress,
              </Typography>
              <Typography variant="h3" style={{ fontWeight: '400' }}>
                Logging out could lose unsaved tasks.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="secondary" onClick={() => {}}>
                Cancel
              </Button>

              <Button variant="contained" color="primary" onClick={() => {}}>
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </When>
    </Choose>
  );
};

export default AjaxInterceptors;
