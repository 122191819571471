/* eslint-disable */
import React, { lazy /* , useEffect */ } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { Skeleton } from '@material-ui/lab';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const Auth = lazy(() => import('../screens/Auth'));
const Login = lazy(() => import('../screens/Auth/Login'));

const PublicRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const userCookie = Cookies.get('user');
  let userType = '';
  React.useEffect(() => {
    if (cookies.token) {
      const userData = JSON.parse(atob(userCookie));
      userType = (userData.userType && userData.userType.type) || null;

      if (userType == 'uberadmin') {
        navigate('../app');
      } else {
        navigate('../app/device-database');
        Cookies.set('path', 'device-database');
      }
      setAuth(false);
    } else {
      setAuth(true);
      Cookies.remove('path');
    }
  }, [cookies.token, navigate]);

  return auth ? <Component {...props} /> : <Loader />;
};

const authNav = [
  {
    path: 'login',
    element: <Login />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
