import React from 'react';
// import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import useStyles from './NoFound.style';

const NoFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={6} md={2}>
              <img
                className={classes.oops}
                src={require('../../assets/404/oops.png').default}
                alt="OOPS"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <p className={classes.msg}>We can’t seem to find the page you’re looking for.</p>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <img
                className={classes.disconnect}
                src={require('../../assets/404/disconnect.png').default}
                alt="Disconnect"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <p className={classes.labeltext}>Error code:</p>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <img
                    className={classes.fournotfour}
                    src={require('../../assets/404/404.png').default}
                    alt="404"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

NoFound.propTypes = {};

export default NoFound;
