/* eslint-disable*/
import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ajax, { abortAllRequest } from '../utils/ajaxHelper';
import { getHostType } from '../utils';
import { string } from 'prop-types';
import { useContext } from 'react';
import { TaskContext } from '../contexts/taskContext';
import { TASK_EVENT } from '../constants/actionTypes';

const useLogin = () => {
  const { company, token, roleId, registerToken } = useParams();
  const [taskState, taskDispatch] = useContext(TaskContext);
  const navigate = useNavigate();

  const callAPi = useCallback(
    async (url, values, { resetForm, setErrors, setStatus, setSubmitting, setFieldError }) => {
      setSubmitting(true);
      let data = values;

      try {
        let companyDetail = {};

        if (token) {
          await ajax.post(
            'password-token-validate',
            {
              token,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          data = { ...values, token };
        }

        if (registerToken) {
          await ajax.post(
            'register-token-validate',
            {
              token: registerToken,
            },
            {
              networkParams: { showLoader: false },
            },
          );
          data = { ...values };
          data.token = registerToken;
        }

        const response = await ajax.post(url, data, {
          networkParams: { showLoader: false },
          headers: {
            ...companyDetail,
            hosttype: getHostType(roleId),
          },
        });
        if (!response.fieldMessage) {
          resetForm();
        }
        setStatus({ success: true });

        return response;
      } catch (error) {
        console.log(error);
        setStatus({ success: false });
        setErrors({ submit: error.message });
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
    [token, company, roleId, registerToken],
  );

  const login = useCallback(
    async (values, actions) => {
      try {
        const loginResult = await callAPi('login', values, actions);

        if (loginResult.status) {
          window.location.reload(false);
        } else if (!loginResult.status) {
          actions.setFieldError(loginResult.field, loginResult.fieldMessage);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );

  const resetPasswordMail = useCallback(
    async (values, actions, ref) => {
      try {
        await callAPi('reset-password-mail', values, actions);
        ref.reset();
      } catch (e) {
        console.log('error');
        ref.reset();
      }
    },
    [callAPi],
  );

  const resetpassword = useCallback(
    async (values, actions) => {
      await callAPi('reset-password', values, actions);
      navigate('../../login');
    },
    [callAPi, navigate],
  );

  const setUserPassword = useCallback(
    async (values, actions) => {
      await callAPi('set-user-password', values, actions);
      navigate('../../login');
    },
    [callAPi, navigate],
  );

  const twoFactor = useCallback(
    async (values, actions) => {
      try {
        values.type = 'resend';
        const loginResult = await callAPi('login', values, actions);
        if (loginResult.deviceAuth && loginResult.status) {
          window.location.reload(false);
          setTimeout(function () {
            navigate('../app');
          }, 50);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );

  const submitOTP = useCallback(
    async (values, actions) => {
      try {
        const otpResult = await callAPi('verify-otp-and-login', values, actions);

        if (otpResult.deviceAuth && otpResult.status) {
          window.location.reload(false);
          setTimeout(function () {
            navigate('../app');
          }, 50);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi, navigate],
  );
  const abortAllRequest = (source) => {
    source.cancel(); ///Operation canceled by the user.
  };
  const logout = useCallback(async () => {
    try {
      await ajax.post('logout');
    } catch (error) {
      // console.warn(error);
    } finally {
      Cookies.remove('token');
      Cookies.remove('user');
      Cookies.remove('path');
      navigate('../login', { replace: true });
    }
  }, [navigate, taskState]);

  const keepActiveSession = useCallback(async () => {
    try {
      await ajax.post('keep-active');
    } catch (error) {
    } finally {
    }
  }, []);

  return {
    login,
    resetPasswordMail,
    resetpassword,
    twoFactor,
    submitOTP,
    logout,
    setUserPassword,
    keepActiveSession,
  };
};

export default useLogin;
