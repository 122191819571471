/* eslint-disable  */

import { makeStyles } from '@material-ui/core/styles';
// import { drawerWidth } from '../../constants/globalConstants';
import bgstyle03 from '../../assets/images/bgstyle03.jpg';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  // },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  paperStyleTwo: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '10px',
  },

  paperWhite: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(4),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '10px',
  },

  paperQuestion: {
    backgroundImage: `url(${bgstyle03})`,
    backgroundPosition: 'top center',
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    textAlign: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },

  package: {
    backgroundImage: `url(${bgstyle03})`,
    backgroundPosition: 'top center',
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    textAlign: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    cursor: 'pointer',
    minHeight: '460px',
    border: '5px solid #f2efea',
    marginBottom: '45px',
  },

  paperMessage: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(1.5),
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '5px',
  },

  paperField: {
    border: '2px solid rgba(162,156,153,0.2)',
    boxShadow: 'none',
    borderRadius: 15,
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: '18px',
    minHeight: '60px',
  },

  paperHeadingPage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },

  paperHeadingSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  },

  heading: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0),
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  subheading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    // fontStyle: 'Italic',
  },
  GrayColor: {
    color: 'rgba(0, 0, 0, 0.6)',
  },

  selectcreatebtn: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#2876A4',
    padding: '10px 5px 10px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#2876A4',
      boxShadow: 'none',
    },
  },
  iconBox: {
    width: '48px',
    height: '48px',
    minWidth: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    marginRight: '15px',
  },
  StepHeading: {
    color: '#2876A4',
    fontWeight: '700',
  },
  StepSubHeading: {
    fontWeight: '700',
  },
  instructionsBoxContainer: {
    '& ol': {
      margin: '0 0 0 16px',
      padding: '0',
    },
    '& li': {
      marginTop: '10px',
      marginBottom: '15px',
    },
  },
  iconBoxContainer: {
    '& ul': {
      margin: '0',
      padding: '0',
    },
    '& li': {
      listStyle: 'none',
      marginTop: '10px',
      marginBottom: '15px',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& span': {
      fontWeight: '600',
    },
  },
  /* selectdropdown:{
    '& .MuiMenuItem-root':{
      backgroundColor: '#fff',
      color:'red',
    },
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }, */

  waterMark: {
    paddingTop: '13%',
  },

  paperWhiteTitle: {
    color: '#61B3CD !important',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  addListNumber: {
    color: '#61B3CD',
  },
  addlistBg: {
    backgroundColor: 'rgba(162,156,153,0.2)',
    borderRadius: '5px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addListSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backButton: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    direction: 'column',
    justify: 'flex-start',
    // '& > *': {
    //   marginTop: theme.spacing(0.5),
    //   marginBottom: theme.spacing(0.5),
    //   marginLeft: theme.spacing(2),
    //   marginRight: theme.spacing(2),
    // },
  },
  small: {
    width: 52,
    height: 52,
    backgroundColor: '#EBF6FD !important',
  },
  large: {
    width: '100px !important',
    height: '100px !important',
    backgroundColor: '#EBF6FD !important',
  },
  medium: {
    width: '70px !important',
    height: '70px !important',
  },
  logout: {
    fontSize: 18,
    fontWeight: 700,
    padding: '5px 30px',
  },

  multilinestyle: {
    '& .MuiInputLabel-formControl': {
      marginLeft: theme.spacing(0),
      top: '-5px',
    },
    '& .MuiInputLabel-shrink': {
      top: '0',
    },
    '& .Mui-focused': {
      top: '0',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '26px',
    },
  },

  disabledBg: {
    backgroundColor: 'rgba(227, 226, 225, 1)',
  },

  CommonAutocomplete: {
    '& .MuiAutocomplete-input': {
      padding: '0px 4px!important',
    },
    '& .MuiInputLabel-root': {
      // color: "rgba(0, 0, 0, 0.6)",
      fontWeight: '400',
    },
  },
  textarea: {
    border: '2px solid rgba(162,156,153,0.2)',
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    width: '100%',
    resize: 'none',
    minHeight: '90px',
    padding: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeight: '400',
    '&::placeholder': {
      fontWeight: '400',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&::-webkit-input-placeholder': {
      fontWeight: '400',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&::-ms-input-placeholder': {
      fontWeight: '400',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  CustomModalNumber: {
    '& .MuiInputLabel-outlined': {
      paddingLeft: '50px',
    },

    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl': {
      '&::before': {
        content: '"#"',
        display: 'flex',
        width: '50px',
        background: '#F8F9FC',
        height: '46px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '2px solid rgba(162,156,153,0.2)',
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: '16px',
        borderRadius: '8px 0 0 8px',
      },
    },
  },
  CustomkWHNumber: {
    '& .MuiInputLabel-outlined': {
      paddingRight: '50px',
    },

    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl': {
      '&::after': {
        content: '"kWh"',
        display: 'flex',
        width: '55px',
        background: '#F8F9FC',
        height: '46px',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '2px solid rgba(162,156,153,0.2)',
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: '16px',
        borderRadius: '0px 8px 8px 0px',
      },
    },
  },
  CommonInputLabel: {
    fontWeight: '500 !important',
    color: 'rgba(0, 0, 0, 1)',
  },
  VerifiedIcon: {
    marginRight: theme.spacing(1),
  },

  TableToggleBox: {
    // '& .MuiTable-root': {
    //   tableLayout: 'fixed !important',
    // },
    // '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
    //   opacity: '1',
    // },
    '& .MuiTablePagination-input': {
      border: 'none!important',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '1px',
      paddingLeft: '12px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputBase-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
    },
    '& .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded': {
      boxShadow: 'none',
    },
    '& .MuiTablePagination-root:last-child': {
      borderBottom: 'none',
    },
    '& .MuiTabs-root': {
      marginBottom: theme.spacing(4),
      display: 'inline-flex',
      background: '#F1F3F9',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '12px',
      tableLayout: 'fixed',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#FFFFFF',
      color: '#2876A4',
    },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit': {
      borderRadius: '8px',
      margin: theme.spacing(1),
    },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:hover': {
      background: '#EDEFF2',
      color: '#2876A4',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .MuiTableCell-root': {
      '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit': {
        color: 'rgba(0, 0, 0, 0.6)',
        background: '#EDEFF2',
        borderRadius: '8px',
        padding: '6px',
        marginLeft: theme.spacing(1.5),
      },
    },
    '& .Duplicate': {
      background: '#E53451',
      borderRadius: '28px',
      display: 'inline-flex',
      color: '#fff',
      padding: '5px 12px',
      whiteSpace: 'nowrap',
    },
    '& .NewRequest': {
      padding: '5px 12px',
      background: '#008A45',
      borderRadius: '28px',
      display: 'inline-flex',
      color: '#fff',
      whiteSpace: 'nowrap',
    },
    '& .ViewButton': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '12px',
      whiteSpace: 'nowrap',
      border: 'none!important',
    },
  },
  circleIconBox: {
    backgroundColor: '#ffffff',
    border: '4px solid rgba(243,239,234,1)',
    borderRadius: '100px',
    height: '130px',
    width: '130px',
    boxShadow: '0px 0px 10px #eae9e8',
    display: 'inline-flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Overrides style - modal dialog
  closeButton: {
    right: '8px',
    top: '8px',
    textAlign: 'right',
  },

  // material Data Table

  tbAlert: {
    fontSize: '12px',
    backgroundColor: '#61B3CD',
    color: '#ffffff',
    borderRadius: '5px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  materialDT: {
    // backgroundColor: '#ffffff',
    '& .MuiTableCell-root:last-child > div ': { display: 'block !important', textAlign: 'center' },
  },

  actions: {
    // backgroundColor: '#ffffff',
    display: 'block !important',
    textAlign: 'center',
  },

  materialHighlight: {
    backgroundColor: '#e2ddd8',
  },
  // Chat
  chat: {
    Position: 'relative',
    height: '450px',
    overflowY: 'scroll',
  },

  chatLeft: {
    textAlign: 'left',
  },
  chatCenter: {
    textAlign: 'center',
  },
  chatRight: {
    textAlign: 'right',
  },

  chatLeftBox: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: theme.spacing(2),
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontSize: '16px',
    color: '#4B403A',
    display: 'inline-flex',
    boxShadow: '0px 2px 2px rgba(29,25,23,0.09)',
    marginTop: '5px',
    marginBottom: '10px',
    // width: '80%',
  },

  chatRightBox: {
    backgroundColor: 'rgba(127,193,214,1)',
    padding: theme.spacing(2),
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    fontSize: '16px',
    color: '#ffffff',
    // width: 'fit-content',
    boxShadow: '0px 2px 2px rgba(29,25,23,0.09)',
    marginTop: '5px',
    marginBottom: '10px',
    display: 'inline-flex',
    // width: '80%',
    textAlign: 'left',
  },

  chatProW: {
    width: '80px',
    flex: 'inherit',
  },

  profileW: {
    width: '150px !important',
    flex: 'inherit !important',
  },

  selectderror: {
    color: '#ED474A',
    marginLeft: '12px',
    marginRight: '12px',
  },

  // Chip

  selectedTags: {
    color: '#4B403A',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    margin: '10px 5px 0px 5px',
  },

  responsedropzonestyle: {
    '& .makeStyles-dropzonestyle-101': {
      padding: '20px',
      '& .makeStyles-dropzonetitle-102': {
        display: 'flex',
        '& .makeStyles-dropzonetext-103': {
          width: '140px',
          lineHeight: '16px',
        },
      },
    },
  },

  warning: {
    border: '2px solid rgba(162,156,153,0.2)',
    borderRadius: '5px',
    backgroundColor: '#61B3CD',
    '& .MuiTypography-h3': {
      color: '#fff',
    },
  },
  warningicon: {
    display: 'flex',
    backgroundColor: '#fff',
    color: '#2876A4',
    borderRadius: '50%',
    padding: '5px',
  },
  whitebutton: {
    backgroundColor: '#fff',
    color: '#2876A4',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  container1: {
    display: 'flex',
    padding: '10px 20px',
    backgroundColor: '#fff',
    maxWidth: '600px',
    margin: '12px auto',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid #ddd',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
  },

  btn: {
    color: '#fff',
    textAlign: 'center',
    width: '30px',
    height: '40px',
    lineHeight: '40px',
    border: 'none',
  },

  resourceItem: {
    '& img': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(4),
    },
    '& video': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(4),
    },
    '& iframe': {
      boxShadow: '0 2px 10px 0 rgb(0 0 0 / 15%)',
      marginBottom: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '10px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));
export default useStyles;
