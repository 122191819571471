/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/named */
/* eslint-disable  */
import React, { lazy } from 'react';

let appNav = [];

const DeviceDatabase = lazy(() => import('../screens/Main/DeviceDatabase'));
const Reporting = lazy(() => import('../screens/Main/Reporting'));
// const InstallTripswitchHardware = lazy(() => import('../screens/Main/InstallTripswitchHardware'));

appNav = [
  {
    name: 'Device Database',
    path: 'device-database',
    element: <DeviceDatabase />,
    num: 1,
  },
  {
    name: 'Reporting',
    path: 'reporting',
    element: <Reporting />,
    num: 2,
  },
  // {
  //   name: 'InstallTripswitchHardware',
  //   path: 'install-tripswitch-hardware',
  //   element: <InstallTripswitchHardware />,
  //   num: 3,
  // },
];

export default appNav;
